<template>
  <section class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="font_18 has_lightgrey has-text-weight-semibold">
            Información del proveedor
          </p>
        </div>
        <template v-if="dataDetail.product_by_admin">
          <div class="column is-12">
            <p class="font_15 has_gray has-text-weight-bold">
              Barta
            </p>
          </div>
        </template>
        <template v-else>
          <div class="column is-12">
            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="font_15 has_gray has-text-weight-bold">
                  {{ providerName }}
                </p>
              </div>

              <div class="column is-5">
                <p class="font_15 has_gray">
                  Celular/Whatsapp:
                </p>
              </div>
              <div class="column is-7">
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ providerPhone }}
                </p>
              </div>

              <div class="column is-5">
                <p class="font_15 has_gray">
                  Correo electrónico:
                </p>
              </div>
              <div class="column is-7">
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ providerEmail }}
                </p>
              </div>

              <div class="column is-5">
                <p class="font_15 has_gray">
                  Tipo de persona:
                </p>
              </div>
              <div class="column is-7">
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ providerPersonTypeName }}
                </p>
              </div>

              <div class="column is-5">
                <p class="font_15 has_gray">
                  Razón Social:
                </p>
              </div>
              <div class="column is-7">
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ providerBusinessName }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TransactionProviderInformation',
  data () {
    return {
      providerName: null,
      providerPhone: null,
      providerEmail: null,
      providerPersonTypeName: null,
      providerBusinessName: null
    }
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    spreadData () {
      this.providerName = `${this.dataDetail.provider_name} ${this.dataDetail.provider_last_name || ''} ${this.dataDetail.provider_second_last_name || ''}`
      this.providerPhone = this.dataDetail.provider_phone
      this.providerEmail = this.dataDetail.provider_email
      this.providerPersonTypeName = this.dataDetail.provider_person_type_name
      this.providerBusinessName = this.dataDetail.provider_business_name || 'N/A'
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
